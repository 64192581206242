import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { validateEmail } from "src/helpers/validate-email"

const API_URL = `${process.env.GATSBY_API_URL}/dashboard/generate_code/`
const ERR_MSG_EMAIL_TAKEN =
  "Diese Email wird bereits von einem bestehenden Account verwendet."
const ERR_MSG_ALL_CODES_TAKEN =
  "Es wurden bereits alle zur Verfügung stehenden Aktivierungscodes ausgegeben."
const ERR_MSG = "Fehler"

export default function EmailInput(props) {
  const {
    placeholder,
    validate,
    short,
    registrationCode,
    buttonText,
    successText,
  } = props

  const [progress, setProgress] = useState(false)
  const [success, setSuccess] = useState(null)
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    setSuccess(null)
    setError("")
  }, [email])

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    if (!validateEmail(email)) {
      setSuccess(false)
      return
    }

    let customError
    if (validate) {
      customError = validate(email)
    }

    if (customError) {
      setError(customError)
      return
    }

    if (email) {
      setProgress(true)
      fetch(API_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registration_code: registrationCode,
          email,
        }),
      })
        .then(response => {
          if (response.ok) {
            setSuccess(true)
            return
          }
          response.json().then(data => {
            if (
              response.status === 400 &&
              Object.prototype.hasOwnProperty.call(data, "email")
            ) {
              setError(ERR_MSG_EMAIL_TAKEN)
              return
            }
            if (
              response.status === 400 &&
              Object.prototype.hasOwnProperty.call(data, "max_user")
            ) {
              setError(ERR_MSG_ALL_CODES_TAKEN)
              return
            }
            setError(ERR_MSG)
          })
        })
        .catch(err => {
          setError(err)
          return
        })
        .then(() => setProgress(false))
    }
  }

  const renderEmailInput = placeholder => (
    <input
      type="email"
      id="email"
      name="email"
      placeholder={placeholder}
      disabled={progress || success === true}
      required
      value={email}
      onChange={({ target }) => setEmail(target.value)}
      className={cx({
        success: success === true,
        error,
        progress,
      })}
    />
  )

  const renderError = () =>
    error && <div className="result-display error">{error}</div>

  const renderSubmitButton = () => {
    return (
      <div className="submit-button">
        {!success && (
          <button
            type="submit"
            disabled={progress || success === true}
            onClick={handleSubmit}
            className={cx({ progress })}
          >
            {buttonText}
          </button>
        )}
        {success === true && (
          <div className="result-display">{successText} 🎉</div>
        )}
      </div>
    )
  }

  return (
    <form
      className={cx("form", { "form-short": short })}
      onSubmit={handleSubmit}
    >
      {renderEmailInput(placeholder)}
      {renderError()}
      {renderSubmitButton()}
    </form>
  )
}

EmailInput.propTypes = {
  short: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  registrationCode: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  successText: PropTypes.string,
  validate: PropTypes.func,
}

EmailInput.defaultProps = {
  buttonText: "Aktivierungscode anfordern",
  successText: "Erfolg",
}
