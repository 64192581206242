import React from "react"
import PropTypes from "prop-types"
import { Grid, Box } from "@material-ui/core"

const TitleStrip = ({ logos, links }) => {
  return (
    <>
      <div className="title-strip-bg" />
      <Box position="absolute" top={0} left={0} right={0} justify="center">
        <Box display="flex" className="bounded-w">
          <Grid
            container
            className="title-strip"
            alignContent="center"
            justify="space-between"
            spacing={2}
          >
            {logos.map((logo, i) => (
              <Grid item xs key={i.toString()}>
                <a href={links[i] || "/"}>
                  <img className="header-logo smaller" src={logo} />
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

TitleStrip.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
}

TitleStrip.defaultProps = {
  logos: [],
  links: [],
}

export default TitleStrip
