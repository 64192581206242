import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import info from "content/data/info.json"
import cx from "classnames"
import cardStyles from "src/styles/components/card.module.scss"
import EmailInput from "./email-input"
import useIsMobile from "src/hooks/useIsMobile"

const GetStartedSection = ({ emailComponent, title, subtitle, steps }) => {
  const {
    badge_google_play,
    badge_app_store,
    link_google_play,
    link_app_store,
  } = info

  const isMobile = useIsMobile()

  return (
    <section className="section-card short">
      <Grid
        container
        spacing={4}
        className={cx(cardStyles.cardContainer, "cardContainer")}
      >
        <Grid item xs={12} md={7}>
          <h5 className="green-text">{title}</h5>
          <p>
            <b>{subtitle}</b>
          </p>
          <ol>
            {steps.map((step, i) => (
              <>
                <li key={i.toString()}>
                  <span>{step}</span>
                </li>
                {i < steps.length - 1 && <br />}
              </>
            ))}
          </ol>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          spacing={2}
          alignContent="center"
          alignItems="center"
          className="form"
        >
          <Grid
            container
            item
            xs={12}
            justify={isMobile ? "center" : "space-between"}
          >
            <a target="_blank" rel="noopener noreferrer" href={link_app_store}>
              <img
                alt="App Store"
                className="store-badge"
                src={badge_app_store}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={link_google_play}
            >
              <img
                alt="Play Store"
                className="store-badge"
                src={badge_google_play}
              />
            </a>
          </Grid>
          <Grid item xs={12}>
            {emailComponent}
          </Grid>
        </Grid>
      </Grid>
    </section>
  )
}

GetStartedSection.propTypes = {
  emailComponent: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
}

GetStartedSection.defaultProps = {
  title: "Legen Sie direkt los",
  subtitle:
    "Um die App zu installieren, folgen Sie einfach diesen drei Schritten:",
  steps: [
    "Laden Sie sich die Mindance App im App Store / Play Store dem Stichwort “Mindance” herunter.",
    "Füllen Sie dieses Anmeldeformular aus, um einen Aktivierungscode an Ihre E-Mail Adresse, zu erhalten.",
    "Erstellen Sie sich unter Verwendung des Aktivierungscodes Ihr persönliches, anonymes Profil.",
  ],
}

export default GetStartedSection
